// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import TableOutlined from '@ant-design/icons/es/icons/TableOutlined';
import FileOutlined from '@ant-design/icons/es/icons/FileOutlined';
import FormOutlined from '@ant-design/icons/es/icons/FormOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined'
  export default {
    SmileOutlined,
TableOutlined,
FileOutlined,
FormOutlined,
SettingOutlined
  }