export default {
  'pages.layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  'pages.login.accountLogin.tab': '账户密码登录',
  'pages.login.accountLogin.errorMessage': '错误的用户名和密码(admin/ant.design)',
  'pages.login.failure': '登录失败，请重试！',
  'pages.login.success': '登录成功！',
  'pages.login.username.placeholder': '用户名: admin or user',
  'pages.login.username.required': '用户名是必填项！',
  'pages.login.password.placeholder': '密码: ant.design',
  'pages.login.password.required': '密码是必填项！',
  'pages.login.phoneLogin.tab': '手机号登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.phoneNumber.placeholder': '请输入手机号！',
  'pages.login.phoneNumber.required': '手机号是必填项！',
  'pages.login.phoneNumber.invalid': '不合法的手机号！',
  'pages.login.captcha.placeholder': '请输入验证码！',
  'pages.login.captcha.required': '验证码是必填项！',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'pages.login.rememberMe': '自动登录',
  'pages.login.forgotPassword': '忘记密码 ?',
  'pages.login.submit': '登录',
  'pages.login.loginWith': '其他登录方式 :',
  'pages.login.registerAccount': '注册账户',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',
  'pages.searchTable.createForm.newMerchandise': '新建商品',
  'pages.searchTable.createForm.upsertMerchandiseStock': '更新商品库存',
  'pages.searchTable.updateForm.ruleConfig': '规则配置',
  'pages.searchTable.updateForm.basicConfig': '基本信息',
  'pages.searchTable.updateForm.ruleName.nameLabel': '规则名称',
  'pages.searchTable.updateForm.ruleName.nameRules': '请输入规则名称！',
  'pages.searchTable.updateForm.ruleDesc.descLabel': '规则描述',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': '请输入至少五个字符',
  'pages.searchTable.updateForm.ruleDesc.descRules': '请输入至少五个字符的规则描述！',
  'pages.searchTable.updateForm.ruleProps.title': '配置规则属性',
  'pages.searchTable.updateForm.object': '监控对象',
  'pages.searchTable.updateForm.ruleProps.templateLabel': '规则模板',
  'pages.searchTable.updateForm.ruleProps.typeLabel': '规则类型',
  'pages.searchTable.updateForm.schedulingPeriod.title': '设定调度周期',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': '开始时间',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': '请选择开始时间！',
  'pages.searchTable.titleDesc': '描述',
  'pages.searchTable.ruleName': '规则名称为必填项',
  'pages.searchTable.titleCallNo': '服务调用次数',
  'pages.searchTable.titleStatus': '状态',
  'pages.searchTable.nameStatus.default': '关闭',
  'pages.searchTable.nameStatus.running': '运行中',
  'pages.searchTable.nameStatus.online': '已上线',
  'pages.searchTable.nameStatus.abnormal': '异常',
  'pages.searchTable.titleUpdatedAt': '上次调度时间',
  'pages.searchTable.exception': '请输入异常原因！',
  'pages.searchTable.titleOption': '操作',
  'pages.searchTable.config': '配置',
  'pages.searchTable.subscribeAlert': '订阅警报',
  'pages.searchTable.title': '查询表格',
  'pages.searchTable.new': '新建',
  'pages.searchTable.chosen': '已选择',
  'pages.searchTable.item': '项',
  'pages.searchTable.totalServiceCalls': '服务调用次数总计',
  'pages.searchTable.tenThousand': '万',
  'pages.searchTable.batchDeletion': '批量删除',
  'pages.searchTable.batchApproval': '批量审批',
  'pages.stock.searchTable.createForm.newStock': '变更库存',
  'pages.stock.updateForm.itemID.nameLabel': '货号',
  'pages.stock.updateForm.itemName.nameLabel': '商品名称',
  'pages.stock.searchTable.tip.name': '商品名称为必填项',
  'pages.stock.searchTable.tip.itemID': '货号为必填项',
  'pages.stock.searchTable.title': '商品查询',
  'pages.stock.searchTable.retailPrice': '零售单价',
  'pages.stock.searchTable.productLaunchedAt': '上架时间',
  'pages.stock.searchTable.titleOption': '操作',
  'pages.stock.searchTable.activate': '上架',
  'pages.stock.searchTable.deactivate': '下架',
  'pages.searchTable.yuan': '元',
  'pages.stock.searchTable.batchDeactivate': '批量下架',
  'pages.stock.searchTable.batchActivate': '批量上架',
  'pages.stock.searchTable.nameStatus.pending': '待确认',
  'pages.stock.searchTable.nameStatus.active': '可用',
  'pages.searchTable.nameStatus.inactive': '已下架',
  'pages.stock.searchTable.sizeXxsCnt': 'XXS尺码数量',
  'pages.stock.searchTable.sizeXsCnt': 'XS尺码数量',
  'pages.stock.searchTable.sizeSCnt': 'S尺码数量',
  'pages.stock.searchTable.sizeMCnt': 'M尺码数量',
  'pages.stock.searchTable.sizeLCnt': 'L尺码数量',
  'pages.stock.searchTable.sizeXLCnt': 'XL尺码数量',
  'pages.stock.searchTable.totalCnt': '库存总数',
  'pages.order.searchTable.title': '订单列表',
  'pages.order.updateForm.uid.nameLabel': '订单号',
  'pages.order.titleDesc': '描述',
  'pages.order.titleStatus': '状态',
  'pages.order.nameStatus.pendingPayment': '待支付',
  'pages.order.nameStatus.paymentProcessing': '支付处理中',
  'pages.order.nameStatus.paid': '已支付',
  'pages.order.nameStatus.abnormal': '异常',
  'pages.order.totalAmount': '总价',
  'pages.order.orderCreatedAt': '创建时间',
  'pages.order.chosen': '已选择',
  'pages.order.item': '个订单',
  'pages.order.yuan': '元',
  'pages.settings.id': 'ID',
  'pages.settings.user.username': '用户名',
  'pages.settings.user.name': '姓名',
  'pages.settings.user.last_name': '姓',
  'pages.settings.user.first_name': '名',
  'pages.settings.user.address': '联系地址',
  'pages.settings.user.phone': '联系电话',
  'pages.settings.user.access': '权限角色',
  'pages.settings.user.titleOption': '操作',
  'pages.settings.user.updatePassword': '更改密码',
  'pages.settings.user.new': '新建用户',
};
