// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import TableOutlined from '@ant-design/icons/TableOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined'

export default {
  SmileOutlined,
TableOutlined,
FileOutlined,
FormOutlined,
SettingOutlined
}
    