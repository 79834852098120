// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  return request<{
    data: API.CurrentUser;
  }>('/api/currentUser', {
    method: 'GET',
    ...(options || {}),
  });
}

export async function currentUserSelect(options?: { [key: string]: any }) {
  let data = await currentUser()
  return new Promise<API.CurrentUserSelect>(resolve=>{data.data.userid, data.data.name});
}

export async function getUserList(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    role?: string;
  },
  options?: { [key: string]: any }
) {
  return request <API.UserList>('/api/v1/settings/users', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  })
}

export async function createUser(body: API.User, options?: { [key: string]: any}) {
  return request<API.User>('/api/v1/settings/user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function updateUserPassword(body: API.UpdateUserPasswordRequest, options?: { [key: string]: any}) {
  return request<API.CommonResponse>('/api/v1/settings/user_password', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/login/outLogin', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  return request<API.LoginResult>('/api/login/account', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>('/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取规则列表 GET /api/rule */
export async function rule(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('/api/rule', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 新建规则 PUT /api/rule */
export async function updateRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 新建规则 POST /api/rule */
export async function addRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeRule(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/rule', {
    method: 'DELETE',
    ...(options || {}),
  });
}


/** 新建商品 */
export async function createMerchandise(body: API.CreateMerchandiseItemParams, options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/v1/merchandise', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function createOrUpdateMerchandiseStock(body: API.CreateOrUpdateMerchandiseStockItemParams, options?: { [key: string]:any}) {
  return request<API.CommonResponse>(`/api/v1/merchandise_stock/add_stock`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function getAllColors(options?: { [key: string]: any }) {
  return request <API.ColorList>('/api/v1/merchandise_stock/colors',{
    method: 'GET',
    ...(options || {}),
  });
}

export async function getColorsByPage(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any }) {
  return request <API.ColorList>('/api/v1/settings/colors', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  })
}

export async function createColor(body: API.CreateColorParams,
  options?: { [key: string]: any }) {
    return request <API.ColorItemResp>('/api/v1/settings/color', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    })
}

export async function getAllSizes(options?: { [key: string]: any }) {
  return request <API.SizeList>('/api/v1/merchandise_stock/sizes',{
    method: 'GET',
    ...(options || {}),
  });
}


export async function getSizesByPage(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any }) {
  return request <API.SizeList>('/api/v1/settings/sizes', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  })
}

export async function createSize(body: API.CreateSizeParams, options?: { [key: string]: any }) {
  return request<API.SizeItemResp>('/api/v1/settings/size', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  })
}

export async function activateStock(ids: number[],options?: { [key: string]: any }) {
    return request<API.CommonResponse>(`/api/v1/stock/activate`,
    {
      method: 'POST',
      params: {
        'id[]': ids,
      },
      ...(options || {}),
    }
  );
};


export async function deActivateStock(ids: number[], options?: { [key: string]: any }) {
    return request<API.CommonResponse>(`/api/v1/stock/deactivate`,
    {
      method: 'POST',
      params: {
        'id[]': ids,
      },
      ...(options || {}),
    }
  );
};

/** 获取库存列表 GET /api/v1/merchandise/list */
export async function getMerchandiseList(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    status?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.MerchandiseList>('/api/v1/merchandise/list',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

export async function getMerchandiseColorList(
  params: {
    merchandise_id: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.ColorList>('/api/v1/merchandise/list_available_colors',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

export async function getMerchandiseColorSizeList(
  params: {
    merchandise_id: number;
    color_id: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.SizeList>('/api/v1/merchandise/list_available_color_sizes',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

export async function getOrderList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
){
  return request<API.OrderList>('/api/v1/order',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

export async function getOrderByID(
  params: {
    id: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.OrderResponse>(`/api/v1/order/${params.id}`,
    {
      method: 'GET',
      ...(options || {}),
    }
  );
}

export async function submitPreorderForm(params: any, options?: { [key: string]: any },) {
  return request<API.OrderResponse>('/api/v1/preorder', {
    method: 'POST',
    data: params,
    ...(options || {}),
  });
}

export async function downloadInvoice(
  params: {
    order_uid: string;
    token: string;
  },
  options?: { [key: string]: any },
) {
  return request('/api/v1/open/order/download_invoice',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

export async function getDownloadToken(
  params: {
    order_id: number;
  },
  options?: { [key: string]: any },
){
  return request<API.DownloadTokenResponse>('/api/v1/order/invoice_download_token',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}